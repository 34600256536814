

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.noselection{
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

html, body{
    margin:0px;
    padding:0px;
    background:#ffffff;
    height: auto;
    width: 100%;
    overflow: auto;
}

.clip-board{
    position:absolute;
    opacity:0;
    pointer-events:none;
    width:0px;
    height:0px;
}

.nowrap{
    white-space: nowrap;
}

.times-circle {
    display: block;
    height: 100%;
    width: 100%;

    & > circle {
        fill: #ffffff;    
    }
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

i { 
    font-style: italic; 
    }

em{
    font-style: italic; 
}
