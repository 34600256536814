
#mobileSwipe{
    display:none;
}

#directoryBody{
    overflow-x:hidden;
}

.desktop{
    display:block;
}

.mobile{
    display:none!important;
}

#menuBar{
    position:absolute;
    background-color:#ffffff;
    width:100%;
    left:0px;
    -webkit-transition: all 1s; -moz-transition: all 1s; -o-transition: all 1s; transition: all 1s;
}

.menuBarOpen{
    height:76px;
    top:463px;
    
}

.menuBarClose{
    height:33px;
    top:66px;
}

#menuBarInner{
    position:absolute;
    width:80%;
    
    height:33px;
    left:50%;
    transform:translateX(-50%);
    -webkit-transition: all 1s; -moz-transition: all 1s; -o-transition: all 1s; transition: all 1s;
}

.menuBarInnerOpen{
    top:42px;
}

.menuBarInnerClose{
    top:0px;
}

.menuItem{
    position:absolute;
    height:28px;
    font-family: "Lato";
    font-size: 12px;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    cursor:pointer;
    display:inline-block;
}

.menuRelative{
    position:relative;
}

#menu4{
    left:0px;
    top:0px;
    width:446px;
    height:33px;
    border:solid 1px #9B9B9B;
    border-radius: 16px;
    overflow:hidden;
    background-color:#ffffff;
}

#menu3{
    left:470px;
    top:10px;
    width:92px;
}

#menu2{
    left:580px;
    top:10px;
    width:105px;
}

#menu5{
    right:50px;
    top:10px;
}

#clearSearch{
    position:absolute;
    top:0px;
    right:0px;
    height:33px;
    width:40px;
    background:#ffffff;
    color:#000000;
    
    font-family: "Lato";
    font-size: 12px;
    color: #000000;
    line-height:33px;
    text-align: center;
    cursor:pointer;

}

.menuText{
    
}

.menuArrow{
    position:absolute;
    top:0px;
    right:0px;
    width:22px;
    height:12px;
    background: url('../img/menuDownArrow.png') no-repeat center center;
    pointer-events:none;
}

#menu2panel{

    position:absolute;
    top:28px;
    left:-35px;/*calc(100vw - 40px);*/
    width:174px;
    height:auto;
    border-left:solid 1px #B59343;
    border-right:solid 1px #B59343;
    border-bottom:solid 1px #B59343;
    background-color:#ffffff;
    display:none;
    padding:20px 0px;
    pointer-events:none;
}

#menu3panel{
    position:absolute;
    top:28px;
    left:-38px;
    width:174px;
    height:30vh;
    border-left:solid 1px #B59343;
    border-right:solid 1px #B59343;
    border-bottom:solid 1px #B59343;
    background-color:#ffffff;
    display:none;
    padding:20px 0px;
}

#menu3panelScroll{
    width:100%;
    height:100%;
    overflow-y:scroll;
}

#mobileMenu2panel{
    display: none;
}

#mobileMenu3panel{
    display: none;
}

.dropDownMenuItem{
    padding:9px 0px;
    pointer-events:all;
    font-family: "Conduit";
    font-size: 14px;
    color: #4A4A4A;
    letter-spacing: 1.4px;
    text-align: center;
}

.dropDownMenuItem:hover{
    color: #B59343;
}

.search{
    position:absolute;
    left:24px;
    top:5px;
    width:398px;
    height:22px;
    
}

#searchFilter{
    width:398px;
    height:22px;
    overflow:visible;
    line-height:12px;
    font-family: "Lato";
    font-size: 12px;
    color: #000000;
    letter-spacing: 0;
    border:none;
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none;
    text-transform:uppercase;
    
}

#projectsWrapper{
    width:100vw;
    background:#ffffff;
    display:none;
    -webkit-transition: top 1s, height 1s; -moz-transition: top 1s, height 1s; -o-transition: top 1s, height 1s; transition: top 1s, height 1s;
    overflow-x:hidden;
}

.projectsWrapperOpen{
    height:100vh;
}

.projectsWrapperClose{
    height:101vh;
}

#videoContent{
    position:absolute;
    -webkit-transition: all 1s; -moz-transition: all 1s; -o-transition: all 1s; transition: all 1s;
}

.videoContentOpen{
    top:545px;
}

.videoContentClose{
    top:116px;
}

.videoContentCartVisible{
    width:70%;
}

.videoContentCartHidden{
    width:100%;
}

#cartNumberBg{
    position:absolute;
    top:-11px;
    right:-20px;
    height:20px;
    width:20px;

    background-color:#B59343;
    border-radius: 10px;
    display:none;
}

#cartNumber{
    position:absolute;
    top:2px;
    left:0px;
    height:15px;
    width:20px;

    font-family: "Lato";
    font-size: 12px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;

}

#cartField{
    position:fixed;
    border-left:solid 1px #eeeeee;
    -webkit-transition: right 1s, top 1s, height 1s; -moz-transition: right 1s, top 1s, height 1s; -o-transition: right 1s, top 1s, height 1s; transition: right 1s, top 1s, height 1s;
    background:#ffffff;
    overflow-y:scroll;
    overflow-x:hidden;
    width:30%;
}

#cartContent{
    width:100%;
    height:100%;
}

.cartHidden{
    right:-30%;
}

.cartVisible{
    right:0px;
}

.cartFieldClose{
    top:116px;
    height:calc(100vh - 116px);
}

.cartFieldOpen{
    top:540px;
    height:calc(100vh - 540px);
}

#cartInner{
    width:calc(100% - 40px);
    padding:20px;
    /*overflow-y:scroll;*/
}

.cartRow{
    position:relative;
    width:calc(100% - 10px);
    height:122px;
    padding:5px;
    margin:5px 0px;
    border-bottom:solid 1px #E9E9E9;

    font-family: "Seravek";
    font-size: 15px;
    color: #4A4A4A;
    letter-spacing: -0.9px;
    pointer-events:none;
}

.cartImg{
    position:absolute;
    height:75px;
    width:100px;
    top:34px;
    left:16px;
    background-repeat:no-repeat;
    background-position: center center;
    background-size: auto 100%;
}

.cartItemText{
    position:absolute;
    left:130px;
    top:56px;
    width:100px;
    
    font-family: "Seravek";
    font-size: 12px;
    color: #4A4A4A;
    letter-spacing: -0.1px;
}

.cartItemTitle{
    position:absolute;
    left:130px;
    top:73px;
    width:300px;
    
    font-family: "Conduit";
    font-size: 16px;
    color: #4A4A4A;
    letter-spacing: -0.17px;
}

.cartItemCategory{
    position:absolute;
    left:130px;
    top:88px;
    width:100px;
    font-family: "Seravek";
    font-size: 12px;
    color: #4A4A4A;
    letter-spacing: -0.1px;
}

.cartDelete{
    position:absolute;
    height:20px;
    width:20px;
    top:13px;
    right:0px;
    
    font-family: "Seravek";
    font-size: 30px;
    color: #4A4A4A;
    letter-spacing: 0;
    cursor:pointer;
    pointer-events:all;
}

#cartTrash{
    background:url('../img/trash.png') no-repeat left;
    width:11px;
    height:14px;
    position:absolute;
    right:36px;
    top:2px;
    cursor:pointer;
}

#cartHamburger{
    background:url('../img/hamburger.png') no-repeat left;
    width:14px;
    height:14px;
    position:absolute;
    right:61px;
    top:2px;
    cursor:pointer;
}

#cartHamburgerMenu{
    position:absolute;
    padding:15px;
    /*height:118px;*/
    height:158px;
    width:201px;
    top:20px;
    right:20px;
    border:solid 1px #979797;
    background-color:#ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    display:none;

}

#cartHamburgerMenu div{
    padding:9px 0px;
    pointer-events:all;
    font-family: "Conduit";
    font-size: 14px;
    color: #4A4A4A;
    letter-spacing: 1.4px;
    text-align: center;
    cursor:pointer;
}

#cartHamburgerMenu div:hover{
    color:#B59343;
}

#cartPdf div{
    background:url('../img/pdf.png') no-repeat left;
    width:9px;
    height:10px;
    overflow:hidden;
    display:inline-block;
}

#cartCopy div{
    background:url('../img/copy.png') no-repeat left;
    width:14px;
    height:14px;
    overflow:hidden;
    display:inline-block;
}

#cartCopyNotification{
    position:absolute;
    top:87px;
    left:50%;
    transform:translateX(-50%);
    width:100%;

    font-family: "Conduit";
    font-size: 10px;
    color: #4A4A4A;
    letter-spacing: 1.4px;
    text-align: center;
    pointerEvents:none;
    opacity:0;
}

#cartShare div{
    background:url('../img/copy.png') no-repeat left;
    width:14px;
    height:14px;
    overflow:hidden;
    display:inline-block;
}

#cartReplace div{
    background:url('../img/replace.png') no-repeat left;
    width:14px;
    height:14px;
    overflow:hidden;
    display:inline-block;
}

#cartHamburgerMenu div:hover div{
    background-position:-20px 3px;
}

#flickityContent{
    display:none;
}

#featuredContent{
    position:absolute;
    left:0px;
    width:calc(100vw - 20px);
    padding: 40px 0px 0px 20px;
    overflow:hidden;
    -webkit-transition: top 1s, height 1s; -moz-transition: top 1s, height 1s; -o-transition: top 1s, height 1s; transition: top 1s, height 1s;
}

.featuredContentOpen{
    top:60px;
    height:400px;
}

.featuredContentClose{
    top:0px;
    height:60px;
}

#projectsContent{
    width:calc(100% - 20px);
    padding: 9px 0px 0px 20px;
}

#collapsingHeader{
    width:100%;
    background:#ffffff;
    position:fixed;
    top:0px;
    left:0px;
    display:none;
    -webkit-transition: all 1s; -moz-transition: all 1s; -o-transition: all 1s; transition: all 1s;
}

.collapsingHeaderOpen{
    height:540px;
}

.collapsingHeaderClose{
    height:116px;
}

#headerInner{
    position:absolute;
    width:100%;
    top:0px;
    background:#000000;
    overflow:hidden;
    border-bottom:solid 2px #B59343;
    -webkit-transition: all 1s; -moz-transition: all 1s; -o-transition: all 1s; transition: all 1s;
}

.headerInnerOpen{
    height:61px;
}

.headerInnerClose{
    height:30px;
}

.headerBar{
    position:absolute;
    background:#B59343;
    width:100vw;
    height:1px;
    margin:0px auto;
    top:60px;
}

.headerBar2{
    background:#9B9B9B;
    width:calc(100vw - 40px);
    height:1px;
    margin:0px auto;
   
}

.headerTitle{
    background: url('../img/courageous_logo.svg') no-repeat center center;
    position:absolute;
    width:241px;
    height:24px;
    top:50%;
    transform:translateY(-50%);
    right:20px;
}

#headerArrowWrapper{
    position:absolute;
    width:1px;
    height:1px;
    top:50%;
    transform:translateY(-50%);
    left:20px;
    -webkit-transition: 0.5s; -moz-transition: 0.5s; -o-transition: 0.5s; transition: 0.5s;
}

#headerArrow{
    background: url('../img/upDownArrow.svg') no-repeat center center;
    position:absolute;
    width:16px;
    height:9px;
    left:50%;
    top:50%;
    transform:translate(-50%,-50%);
    
    
}

#projectWrapper{
    width:100%;
}

.contentWrapper{
    width:100%;
}

.selectrow{
    height:285px;
    width:100%;
    position:relative;
    left:0px;
    user-select: none;
}

.row{
    height:361px;
    width:100%;
    position:relative;
    left:0px;
    user-select: none;
    border-bottom:solid 1px #EEEEEE;
    margin-bottom:30px;
    overflow:hidden;
}

.rowHead, .rowSelectsHead{
    position:absolute;
    left:0px;
    top:0px;
    width:100%;
    height:15px;
}

.rowHeadTitle {
    position:absolute;
    left:0px;
    top:0px;
    width:auto;
    height:20px;
    
    font-family: "Lato";
    font-size: 18px;
    color: #4A4A4A;
    letter-spacing: 0.45px;
    text-transform:uppercase;
    padding: 0px 5px;
}

#rowHeadSelectsTitle{
    position:absolute;
    left:0px;
    top:0px;
    width:auto;
    height:20px;
    
    font-family: "Conduit";
    font-weight: 800;
    font-size: 27px;
    
    letter-spacing: 0;
    text-transform:uppercase;
    padding: 0px 5px;
    
}

#rowHeadPlaylistTitle{
    position:absolute;
    left:135px;
    top:0px;
    width:auto;
    height:20px;
    
    font-family: "Conduit";
    font-size: 27px;
    
    letter-spacing: 0;
    text-transform:uppercase;
    padding: 0px 5px;
    display:none;
}

.rowBody, .rowSelectsBody{
    position:relative;
    left:0px;
    top:30px;
    width:100%;
    height:361px;
    overflow-y:hidden;
    overflow-x:scroll;
}

.rowInner, .rowSelectsInner{
    position:absolute;
    left:0px;
    top:0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height:330px;
}

#rowSelectsInner{
    border-bottom:none;
}

#mainCarousel{
    display:none;
}


.carousel-cell {
    display:none;
}

.scrollArrowLeft, .scrollSelectsArrowLeft{
    position:absolute;
    right:20px;
    top:0px;
    width:8px;
    height:16px;
    background: url('../img/left.png') no-repeat center center;
    cursor:pointer;
}


.scrollArrowRight, .scrollSelectsArrowRight{
    position:absolute;
    right:40px;
    top:0px;
    width:8px;
    height:16px;
    background: url('../img/right.png') no-repeat center center;
    cursor:pointer;
}

.scrollInactive{
    pointer-events:none;
    opacity:0.2;
}

.scrollActive{
    pointer-events:all;
    opacity:1;
}

.hideItem{
    display:none;
}

.showItem{
    display:block;
}

.row-show-hide{
    position:relative;
    top:5px;
    width:200px;
    height:150px;
    margin-right:20px;
    border:solid 1px #B59343;
    cursor:pointer;
}

.black{
    background-color:#000000;
}

.grey{
    background-color:#ffffff;
}

.row-show-hide-text{
    position:absolute;
    width:100%;
    height:18px;
    left:0px;
    top:50%;
    transform:translateY(-50%);

    font-family: "Lato";
    font-size: 12px;
    color: #B59343;
    letter-spacing: 0.7px;
    text-align: center;
}

.rowItem, .rowSelectsItem{
    position:relative;
    top:5px;
    width:200px;
    height:350px;
    margin-right:20px;
    pointer-events:none;
    overflow:hidden;
    background-Image: none;
}

.rowItemBgImg, .rowSelectsItemBgImg{
    display:none;
}

.rowSelectsItemImgWrapper{
    position:absolute;
    top:0px;
}

#rowBgBody{
    display:none;
}

#floatingBg{
    display:none;
}

.rowItemImg, .rowSelectsItemImg{
    position:absolute;
    top:0px;
    width:240px;
    height:150px;
}

.rowSelectsItemDescription{
    display:none;
}

.rowItemInner, .rowItemSelectsInner{
    cursor:pointer;
    width:100%;
    height:270px;
    pointer-events:auto;
    /*border:solid 1px #ffa000;*/
    box-sizing: border-box;
}

.rowItemText, .rowItemSelectsText{
    position:absolute;
    left:0px;
    top:160px;
    width:200px;
    
    font-family: "Lato";
    font-size: 11px;
    color: #4A4A4A;
    letter-spacing: -0.09px;
    pointer-events:none;
}

.rowItemDuration, .rowItemSelectsDuration{
    position:absolute;
    right:1px;
    top:160px;
    height:10px;

    font-family: "Lato";
    font-size: 11px;
    color: #4A4A4A;
    letter-spacing: -0.09px;
    pointer-events:none;
}

.rowItemTitle, .rowItemSelectsTitle{
    position:absolute;
    left:0px;
    top:176px;
    width:200px;
    font-family: "Lato";
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    letter-spacing: -0.25px;
    line-height: 20px;
    pointer-events:none;
    overflow:hidden;
    text-transform: capitalize;
}

.rowItemCategory, .rowItemSelectsCategory{
    position:absolute;
    left:0px;
    top:249px;
    width:200px;
    
    font-family: "Lato";
    font-size: 9px;
    color: #4A4A4A;
    letter-spacing: 0;
    pointer-events:none;
}

.rowItemAddCart, .rowItemSelectsAddCart{
    position:absolute;
    left:0px;
    top:270px;
    width:25px;
    height:27px;
    pointer-events:auto;
    font-family: "Seravek";
    font-size: 25px;
    line-height:25px;
    color: #4A4A4A;
    cursor:pointer;
}

.hamburger{
    display:none;
}

.hamburgerMenu{
    display:none;
}

.viewheader{
    position:absolute;
    width:100%;
    background:#ffffff;
    top:0px;
    left:0px;
    height:60px;
}

.video-player{
    position:fixed;
    top:0px;
    left:100vw;
    width:100vw;
    height:100vh;
    background-color:#000000;

    overflow-x:auto;
    overflow-y:scroll;
    -webkit-transition: opacity 1s; -moz-transition: opacity 1s; -o-transition: opacity 1s; transition: opacity 1s;
    opacity:0;
}

.videoplayer-header{
    width:100%;
    background:#000000;
    position:absolute;
    top:0px;
    left:0px;
    height:48px;
}

.videoplayer-headerInner{
    position:relative;
    height:60px;
    width:95%;
    margin: 0px auto;
}

.videoplayer-headerBar{
    background:#B59343;
    width:100vw;
    height:1px;
    margin:2px auto 0px auto;
    
}

#videoplayerBack{
    position:absolute;
    left:16px;
    top:23px;
    width:50px;
    height:20px;

    font-family: "Lato";
    font-size: 14px;
    color: #9B9B9B;
    letter-spacing: -0.16px;
    text-align: left;
    cursor:pointer;
}

#videoplayerBack2{
    display:none;
}

.embed-container {
    position: relative;
    overflow: hidden;
    top:100px;
    height:calc(100vh - 48px);
    pointer-events:none;
}
    
.embed-container iframe, .embed-container object, .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:60%;
    pointer-events:auto;
    }

#videoEmbedWrapper{
    height:60%;
}

#videoPlayerText{
    position:absolute;
    top:70%;
    left:50%;
    transform:translateX(-50%);
    margin-left:auto;
    margin-right:auto;
}

#videoPlayerBrand{
    width:50%;
    margin-top:30px;
    margin-bottom:10px;
    font-family: "Lato";
    font-size: 15px;
    color: #9B9B9B;
    letter-spacing: -0.12px;
    text-align: left;
}

#videoPlayerTitle{
    width:70%;
    margin-bottom:5px;
    font-family: "Lato";
    font-size: 30px;
    color: #FFFFFF;
    letter-spacing: -0.22px;
    text-align: left;
}

#videoPlayerDescription{
    width:70%;
    margin-bottom:5px;
    font-family: "Lato";
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0.2px;
    line-height: 24px;
    text-align: left;   
}

#videoPlayerCategoryMobile{
    display:none;
}

#videoPlayerCategoryDesktop{
    width:50%;
    font-family: "Lato";
    font-size: 14px;
    color: #9B9B9B;
    letter-spacing: -0.12px;
    text-align: left;
}

#videoPlayerHit{
    position:absolute;
    left:0px;
    top:0px;
    width:100%;
    height:100%;
    background:url('../img/playbutton.png') no-repeat center center;
}


.videoHeaderTitle{
    background: url('../img/courageous_logo.svg') no-repeat center center;
    position:absolute;
    width:241px;
    height:24px;
    top:19px;
    left:50%;
    transform:translateX(-50%);
}

#videoHeaderShare{
    background: url('../img/video_share.svg') no-repeat center center;
    position:absolute;
    width:16px;
    height:22px;
    right:40px;
    top:0px;
    cursor:pointer;
    pointer-events:auto;
}

#videoHeaderVim{
    background: url('../img/vimeo.png') no-repeat center center;
    position:absolute;
    width:17px;
    height:15px;
    right:70px;
    top:7px;
    cursor:pointer;
    pointer-events:auto;
}

#videoHeaderNotification{
    position:absolute;
    width:50px;
    height:40px;
    left:50%;
    transform:translateX(-50%);
    top:-25px;

    font-family: "Conduit";
    font-size: 10px;
    color: #9B9B9B;
    letter-spacing: 1.4px;
    text-align: center;
    pointerEvents:none;
    opacity:0;
}

#videoHeaderPlus{
    position:absolute;
    width:25px;
    height:27px;
    right:10px;
    top:0px;
    cursor:pointer;
    pointer-events:auto;
}

.plus{
    background: url('../img/video_plus.png') no-repeat center center;
}

.minus{
    background: url('../img/video_minus.png') no-repeat center center;
}

.plusgold, .plusSelectsgold{
    background: url('../img/video_plus_gold.png') no-repeat center center;
}

.minusgold, .minusSelectsgold{
    background: url('../img/video_minus_gold.png') no-repeat center center;
}


#videoPlayerHide{
    position:relative;
    margin-left:auto;
    margin-right:auto;
    top:0px;
    height:100%;
    pointer-events:none;
}

#videoPlayerOverlay{
    position:absolute;
    left:0px;
    top:0px;
    width: 100%;
    height: 60%;
    background-color: rgba(0, 0, 0, 0.68); 
}

#videoPlayerPrevBtnHolder{
    position:absolute;
    width:25%;
    left:66px;
    top:50%;
    transform:translateY(-50%);
    cursor:pointer;
    pointer-events:auto;
}

#videoPlayerPrevBtn{
    width:100%;
    font-family: "Lato";
    font-size: 18px;
    color: #B59343;
    letter-spacing: -0.2px;
    text-align: left;
    margin-bottom:15px;
}

#videoPlayerPrevBrand{
    width:100%;
    text-align: left;
    font-family: "Lato";
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: -0.13px;
    margin-bottom:3px;
}

#videoPlayerPrevTitle{
    width:100%;
    text-align: left;
    font-family: "Lato";
    font-size: 24px;
    color: #FFFFFF;
    letter-spacing: -0.26px;
    margin-bottom:5px;
}

#videoPlayerPrevCategory{
    width:100%;
    text-align: left;
    
    font-family: "Lato";
    font-size: 14px;
    color: #FFFFFF;
    letter-spacing: -0.12px;
}

#videoPlayerNextBtnHolder{
    position:absolute;
    width:25%;
    right:66px;
    top:50%;
    transform:translateY(-50%);
    cursor:pointer;
    pointer-events:auto;
}

#videoPlayerNextBtn{
    margin-left:auto; 
    margin-right:0;
    width:100%;
    font-family: "Lato";
    font-size: 18px;
    color: #B59343;
    letter-spacing: -0.2px;
    text-align: right;
    margin-bottom:15px;
}

#videoPlayerNextBrand{
    margin-left:auto; 
    margin-right:0;
    width:100%;
    text-align: right;
    font-family: "Lato";
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: -0.13px;
    margin-bottom:3px;
}

#videoPlayerNextTitle{
    margin-left:auto; 
    margin-right:0;
    width:100%;
    text-align: right;
    font-family: "Lato";
    font-size: 24px;
    color: #FFFFFF;
    letter-spacing: -0.26px;
    margin-bottom:5px;

}

#videoPlayerNextCategory{
    margin-left:auto; 
    margin-right:0;
    width:100%;
    text-align: right;
    font-family: "Lato";
    font-size: 14px;
    color: #FFFFFF;
    letter-spacing: -0.12px;
}

#videoplayerDownload{
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
    width:625px;
    height:324px;
    background:#ffffff;
    display:none;
}


#videoplayerDownloadTitle{
    position:absolute;
    left:36px;
    top:32px;
    font-family: Lato-Regular;
    font-size: 22px;
    color: #4A4A4A;
    letter-spacing: 0.27px;
}

#videoplayerDownloadItem1{
    position:absolute;
    left:35px;
    top:68px;
    width:578px;
    height:44px;
}

#videoplayerDownloadItem2{
    position:absolute;
    left:35px;
    top:124px;
    width:578px;
    height:44px;
}

#videoplayerDownloadItem3{
    position:absolute;
    left:35px;
    top:180px;
    width:578px;
    height:44px;
}

#videoplayerDownloadItem4{
    position:absolute;
    left:35px;
    top:236px;
    width:578px;
    height:44px;
}

#videoplayerDownloadItemTxt1{
    position:absolute;
    left:0px;
    top:0px;
    width:150px;
    height:40px;
    font-family: Lato-Bold;
    font-size: 18px;
    color: #4A4A4A;
    letter-spacing: 0.22px;
    line-height:40px;
}

#videoplayerDownloadItemTxt2{
    position:absolute;
    right:211px;
    top:0px;
    width:237px;
    height:40px;
    font-family: Lato;
    font-size: 18px;
    color: #4A4A4A;
    letter-spacing: 0.22px;
    text-align:right;
    line-height:40px;
}

#videoplayerDownloadItemBtn1{
    position:absolute;
    right:0px;
    top:0px;
    width:191px;
    height:40px;
    background:#000000;
    font-family: Lato-Bold;
    font-size: 14px;
    color: #B59343;
    letter-spacing: 0;
    text-align: center;
    line-height:40px;
}


.prompt-wrapper{
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    display:none;
    opacity:0;
    -webkit-transition: opacity 1s;
    -moz-transition: opacity 1s;
    -o-transition: opacity 1s;
    transition: opacity 1s;
}

.prompt-bg{
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    background:#ffffff;
    opacity:0.6;
    z-index:100;
}

.prompt{
    position:relative;
    width:400px;
    height:160px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    border:solid 1px #000000;
    background:#ffffff;
    z-index:101;
    
}

.pdf-text{
    position:absolute;
    top:50px;
    left:50%;
    transform: translateX(-50%);
    line-height:14px;
    font-size:14px;
    font-family: "Conduit";
    color:#000000;
}

.download-pdf-btn{
    position:absolute;
    top:99px;
    left:91px;
    width:122px;
    height:20px;
    border:solid 1px #000000;
    /*cursor:no-drop;
    opacity:0.3;*/
    cursor:pointer;
}
.download-pdf-btn-txt{
    position:absolute;
    top:5px;
    left:10px;
    line-height:10px;
    font-size:10px;
    font-family: "Conduit";
    color:#000000;
    width:100%;
    height:12px;
    pointer-events:none;
}

.download-pdf-cancel{
    position:absolute;
    top:99px;
    left:233px;
    width:77px;
    height:20px;
    border:solid 1px #000000;cart
    cursor:pointer;
}

.download-pdf-cancel-txt{
    position:absolute;
    top:5px;
    left:10px;
    line-height:10px;
    font-size:10px;
    font-family: "Conduit";
    color:#000000;
    width:100%;
    height:12px;
    pointer-events:none;
}
