

#mobileSwipe{
    position:absolute;
    top:0px;
    left:0px;
    width:100vw;
    height:100vh;
    background:#ffa000;
    opacity:0;
    display:none;
}

#directoryBody{
    overflow-x:hidden;
    width:100vw;
}

#menuBar{
    
    width:100%;
    height:130px;
    left:0px;
    background-color:#ffffff;
}

.menuBarOpen{
    position:absolute;
    top:100vh;
    
}

.menuBarClose{
    position:fixed;
    top:0px;
}

#menuBarInner{
    position:absolute;
    width:80%;
    
    height:130px;
    left:50%;
    transform:translateX(-50%);
    -webkit-transition: all 1s; -moz-transition: all 1s; -o-transition: all 1s; transition: all 1s;
}

.menuItem{
    position:absolute;
    height:28px;
    font-family: Lato;
    font-size: 13px;
    color: #000000;
    letter-spacing: 0.32px;
    cursor:pointer;
    display:inline-block;
}

.menuRelative{
    position:relative;
}

#menu4{
    left:0px;
    top:16px;
    width:100%;
    height:40px;
    border:solid 1px #9B9B9B;
    border-radius: 16px;
    overflow:hidden;
    background-color:#ffffff;
}

#menu3{
    left:5%;
    top:74px;
    width:auto;
    text-align: left;
}

#menu2{
    left:50%;
    transform:translateX(-50%);
    top:74px;
    width:auto;
    text-align: center;
}

#menu5{
    right:5%;
    top:74px;
}

#clearSearch{
    position:absolute;
    top:0px;
    right:0px;
    height:40px;
    width:40px;
    background:#ffffff;
    color:#000000;
    
    font-family: "Lato";
    font-size: 12px;
    color: #000000;
    line-height:40px;
    text-align: center;
    cursor:pointer;

}

#cartNumberBg{
    position:absolute;
    top:-11px;
    right:-16px;
    height:16px;
    width:16px;

    background-color:#B59343;
    border-radius: 10px;
    display:none;
}

#cartNumber{
    position:absolute;
    top:3px;
    left:0px;
    height:8px;
    width:16px;


    font-family: CNNSansDisplay;
    font-size: 8px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;

}

.menuArrow{
    position:absolute;
    top:0px;
    right:-22px;
    width:22px;
    height:12px;
    background: url('../img/menuDownArrow.png') no-repeat center center;
    pointer-events:none;
}

#menu2panel{
    display:none;
    pointer-events:none;
}

#menu3panel{
    display:none;
    pointer-events:none;
}

#mobileMenu2panel{
    position:fixed;
    top:100vh;
    left:0px;
    width:100vw;
    height:45vh;
    background:#eeeeee;
    -webkit-transition: all 0.75s ease-in-out; -moz-transition: all 0.75s ease-in-out; -o-transition: all 0.75s ease-in-out; transition: all 0.75s ease-in-out;
    display: flex;
    flex-flow: column;
}

#mobileMenu3panel{
    position:fixed;
    top:100vh;
    left:0px;
    width:100vw;
    height:45vh;
    background:#eeeeee;
    -webkit-transition: all 0.75s ease-in-out; -moz-transition: all 0.75s ease-in-out; -o-transition: all 0.75s ease-in-out; transition: all 0.75s ease-in-out;
    display: flex;
    flex-flow: column;
}

#mobileMenu2panelScroll{
    width:100%;
    overflow-y:scroll;
    padding: 20px 0px;
    flex: 0 1 auto;
}

#mobileMenu3panelScroll{
    width:100%;
    overflow-y:scroll;
    padding: 20px 0px;
    flex: 0 1 auto;
}

.mobileMenuTop{
    height:30px;
    width:100%;
    background:#ffffff;
    border-top:solid 1px #D8D8D8;
    flex: 0 1 30px;
}

.mobileMenuTopTxt{
    width:auto;
    float:right;
    height:29px;
    font-family: Helvetica;
    font-size: 12px;
    color: #1C75CD;
    letter-spacing: 0.33px;
    text-align: center;
    line-height:30px;
    padding-left:25px;
    padding-right:25px;
    background:#ffffff;
}
                
.mobileMenuPleaseSelect{
    font-family: Helvetica;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    line-height:30px;
    height:30px;
    border-top:solid 1px #9B9B9B;
    border-bottom:solid 1px #9B9B9B;
    margin-top:30px;
    flex: 0 1 30px;
}

.dropDownMenuItem{
    padding:9px 0px;
    pointer-events:all;
    font-family: Helvetica;
    font-size: 18px;
    color: #9B9B9B;
    letter-spacing: 0;
    text-align: center;
}

.dropDownMenuItem:hover{
    color: #B59343;
}

.search{
    position:absolute;
    left:5%;
    top:8px;
    width:90%;
    height:22px;
}

#searchFilter{
    width:100%;
    height:22px;
    overflow:visible;
    line-height:13px;
    
    font-family: Lato;
    font-size: 13px;
    color: #9B9B9B;
    letter-spacing: 0.32px;
    text-align: left;
    border:none;
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none;
    text-transform:uppercase;
}

#headerMobileToggle{
    position:absolute;
    right:30px;
    top:50%;
    width:104px;
    height:17px;
    transform:translateY(-50%);
    background: url('../img/m_togglePill.png') no-repeat center center;
    
    cursor:pointer;
}

#headerMobileToggleSelectsTitle{
    position:absolute;
    left:0px;
    top:0px;
    width:50%;
    height:17px;

    font-family: CNNSansDisplay;
    font-size: 8px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    line-height:17px;
}

#headerMobileTogglePlaylistTitle{
    position:absolute;
    right:0px;
    top:0px;
    width:50%;
    height:17px;

    font-family: CNNSansDisplay;
    font-size: 8px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    line-height:17px;
}

#headerMobileTogglePlaylistNumberBg{
    position:absolute;
    top:-11px;
    right:-2px;
    height:16px;
    width:16px;

    background-color:#ffffff;
    border-radius: 10px;
}

#headerMobileTogglePlaylistNumber{
    position:absolute;
    top:0px;
    left:0px;
    height:7px;
    width:16px;


    font-family: CNNSansDisplay;
    font-size: 7px;
    color: #4A4A4A;
    letter-spacing: 0;
    line-height:16px;
    text-align: center;
}

#collapsingHeader{
    width:100vw;
    background:#ffffff;
    position:static;
    top:0px;
    left:0px;
    display:none;
}

.collapsingHeaderOpen{
    height:100vh;
}

.collapsingHeaderClose{
    height:100vh;
}

#headerInner{
    position:absolute;
    width:100%;
    height:47px;
    top:0px;
    background:#000000;
    overflow:hidden;
    
}

.headerBar{
    position:absolute;
    background:#B59343;
    width:100vw;
    height:1px;
    margin:0px auto;
    top:60px;
}

.headerBar2{
    background:#9B9B9B;
    width:calc(100vw - 40px);
    height:1px;
    margin:0px auto;
   
}

.headerTitle{
    background: url('../img/m_courageous_logo.svg') no-repeat center center;
    position:absolute;
    width:189px;
    height:22px;
    top:50%;
    transform:translateY(-50%);
    left:30px;
}

#featuredContent{
    position:absolute;
    top:47px;
    left:0px;
    width:100vw;
    height:calc(100vh - 47px);
}

#projectsContent{
    width:calc(100% - 16px);
    padding: 0px 0px 0px 16px;
}

#projectWrapper{
    position:absolute;
    top:0px;
    left:0px;
    width:100%;
    height:100%;
}

.selectrow{
    height:100%;
    width:100%;
    position:relative;
    left:0px;
    user-select: none;
    
}

.rowSelectsHead{
    display:none
}

.rowSelectsBody{
    position:relative;
    left:0%;
    top:0px;
    width:100%;
    height:110%;
    overflow-y:auto;
}

#mainCarousel{
    position:absolute;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;
}


.carousel-cell {
    width: 100%;
    height: 100%;
}

.rowSelectsInner{
    display:none;
    /*
    position:absolute;
    left:0px;
    top:0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height:100%;
    */
}

.rowSelectsItem{
    position:relative;
    width:100vw;
    height:calc(100vh - 47px);
    pointer-events:none;
    overflow:hidden;
}

.flickityInner{
    position:absolute;
    left:50%;
    transform:translateX(-50%);
    top:43px;
    cursor:pointer;
    width:80%;
    height:180px;
    pointer-events:auto;
    box-sizing: border-box;
    overflow:hidden;
}

#rowBgBody{
    position:absolute;
    left:0%;
    top:0px;
    width:100%;
    height:110%;
    overflow-y:hidden;
    background-color:#000000;
}

#rowBgInner{
    position:absolute;
    left:0px;
    top:0px;
    height:100%;
}

.rowSelectsBgItem{
    position:absolute;
    width:100vw;
    height:calc(100vh - 47px);
    pointer-events:none;
    overflow:hidden;
    background-color:#000000;
    opacity:0;
    -webkit-transition: opacity 0.5s; -moz-transition: opacity 0.5s; -o-transition: opacity 0.5s; transition: opacity 0.5s;
}

.rowSelectsItemBgImg{
    
    position:absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);

    width:110%;
    height:110%;
    background-repeat:no-repeat;
    background-position: center center;
    background-size: cover;
    filter:blur(2px);
    opacity:0.5;
    
}

.flickityImgWrapper{
    position:absolute;
    top:225px;
    left:10%;
    /*bottom:72px;*/
    
    width:80%;
    height:auto;
    /*background-color:#ffffff;*/
    display: flex;
    flex-direction: column;
    pointer-events:none;
    
}

#floatingBg{
    position:absolute;
    top:299px;
    left:10%;
    bottom:72px;
    
    width:80%;
    height:auto;
    background-color:#ffffff;
    overflow:hidden;
    border-radius: 2px;
    display:none;
}

.flickityImg{
    width:100%;
    height:auto;
    flex-shrink: 0;
    pointer-events:none;
    border-radius: 2px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, .20);
}

.flickityPlayImg{
    position:absolute;
    left:0px;
    top:0px;
    width:100%;
    height:100%;
    flex-shrink: 0;
    pointer-events:auto;
    background: url('../img/playbutton.png') no-repeat center center;
    opacity:0.5;
}

.flickity-page-dots {
  left:50%;
  transform: translateX(-50%);
}

.flickity-page-dots .dot {
  width: 8px;
  height: 8px;
  opacity: 1;
  background: transparent;
  background: #7D7D7D;
  opacity:0.39;
}
/* fill-in selected dot */
.flickity-page-dots .dot.is-selected {
  background: #ffffff;
}

.rowSelectsItemDescription{
    position:relative;
    left:50%;
    transform:translateX(-50%);
    padding-left:20px;
    padding-right:20px;
    padding-top:20px;
    margin-bottom:60px;
    font-family: Lato;
    font-size: 15px;
    color: #4A4A4A;
    letter-spacing: 0.2px;
    line-height: 24px;
    width:100%;
    overflow-y:scroll;
    flex-grow: 1;
    pointer-events:auto;
    display:none;
}

.rowItemSelectsAddCart{
    position:absolute;
    left:50%;
    transform:translateX(-50%);
    bottom:92px;
    width:25px;
    height:25px;
    pointer-events:auto;
    font-family: "Seravek";
    font-size: 25px;
    line-height:25px;
    color: #4A4A4A;
    cursor:pointer;
    display:block;
}

.contentWrapper{
    width:100%;
    height:auto;
    
}

.flickityBrandText{
    position:absolute;
    left:0px;
    top:0px;
    width:100%;
    
    font-family: Lato;
    font-size: 16px;
    font-weight:100;
    color: #EEEEEE;
    letter-spacing: -0.15px;
    
    line-height: 24px;
    pointer-events:none;
    text-transform:uppercase;
}

.flickityTitle{
    position:absolute;
    left:0px;
    top:27px;
    width:100%;
    
    font-family: Lato;
    font-weight:100;
    font-size: 36px;
    color: #F2F2F2;
    letter-spacing: -0.5px;
    line-height: 40px;
    pointer-events:none;
    overflow:hidden;
    text-transform: capitalize;
}

.flickityCategory{
    position:absolute;
    left:0px;
    top:160px;
    width:100%;
    
    font-family: Lato;
    font-size: 12px;
    color: #9B9B9B;
    letter-spacing: 0;
    pointer-events:none;
    text-transform:uppercase;
}

#rowHeadSelectsTitle{
    position:absolute;
    left:0px;
    top:0px;
    width:auto;
    height:20px;
    
    font-family: "Conduit";
    font-size: 27px;
    font-weight: 800;
    
    letter-spacing: 0;
    text-transform:uppercase;
    padding: 0px 5px;
    
}

#rowHeadPlaylistTitle{
    position:absolute;
    left:135px;
    top:0px;
    width:auto;
    height:20px;
    
    font-family: "Conduit";
    font-size: 27px;
    
    letter-spacing: 0;
    text-transform:uppercase;
    padding: 0px 5px;
    display:none;
}

#projectsWrapper{
    width:100vw;
    height:auto;
    display:none;
    oveflow-x:hidden;
}

.projectsWrapperOpen{

}

.projectsWrapperClose{

}

#videoContent{
    position:relative;
    width:100%;
    height:auto;
    min-height:calc(100vh - 130px);
    top: calc(100vh + 130px);
    
}


.row{
    height:286px;
    width:100%;
    position:relative;
    left:0px;
    user-select: none;
    border-bottom:solid 1px #EEEEEE;
    margin-bottom:30px;
    overflow:hidden;
}

.rowHead{
    position:absolute;
    left:0px;
    top:0px;
    width:100%;
    height:15px;
}

.rowHeadTitle{
    position:absolute;
    left:0px;
    top:0px;
    width:auto;
    height:20px;
    
    font-family: Lato;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0.45px;
    text-transform:uppercase;
    padding: 0px 5px;
}

.rowBody{
    position:relative;
    left:0px;
    top:30px;
    width:100%;
    height:286px;
    overflow-y:hidden;
    overflow-x:scroll;
    -webkit-overflow-scrolling: touch;
}

.rowInner{
    position:absolute;
    left:0px;
    top:0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height:286px;
}

.rowItem{
    position:relative;
    top:5px;
    width:120px;
    height:286px;
    margin-right:16px;
    pointer-events:none;
    overflow:hidden;
    background-Image: none;
}

.rowItemImg{
    position:absolute;
    top:0px;
    width:140px;
    height:102px;
}

.rowItemBgImg{
    display:none;
}

.rowItemInner{
    position:absolute;
    left:0px;
    top:0px;
    cursor:pointer;
    width:100%;
    height:102px;
    pointer-events:auto;
    box-sizing: border-box;
}

.rowItemText{
    margin-top:110px;
    width:100px;
    
    font-family: Lato;
    font-size: 9px;
    color: rgba(74,74,74,0.79);
    letter-spacing: 0;
    pointer-events:none;
}


.rowItemDuration{
    position:absolute;
    right:4px;
    top:81px;
    height:15px;
    padding:0px 2px;
    font-family: "Lato";
    font-size: 11px;
    color: #ffffff;
    line-height:15px;
    letter-spacing: -0.09px;
    pointer-events:none;

    background-color: rgba(0,0,0,0.79);
}

.rowItemTitle{
    width:120px;
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    letter-spacing: 0;
    line-height: 20px;
    pointer-events:none;
    overflow:hidden;
    text-transform: capitalize;
}

.rowItemCategory{
    margin-top:6px;
    width:120px;
    font-family: "Lato";
    font-size: 9px;
    color: #4A4A4A;
    letter-spacing: 0;
    pointer-events:none;
}

.flickityAddCart{
    position:absolute;
    right:0px;
    bottom:0px;
    width:18px;
    height:18px;
    pointer-events:auto;
    cursor:pointer;
}

.rowItemAddCart{
    position:absolute;
    left:0px;
    top:198px;
    width:18px;
    height:18px;
    pointer-events:auto;
    
    cursor:pointer;
}

.plus{
    background: url('../img/video_plus.png') no-repeat center center;
}

.minus{
    background: url('../img/video_minus.png') no-repeat center center;
}

.plusgold{
    background: url('../img/video_plus_gold.png') no-repeat center center;
    background-size:18px 18px;
}

.minusgold{
    background: url('../img/video_minus_gold.png') no-repeat center center;
    background-size:18px 18px;
}

.plusSelectsgold{
    background: url('../img/video_plus_gold.png') no-repeat center center;
}

.minusSelectsgold{
    background: url('../img/video_minus_gold.png') no-repeat center center;
}

.hideItem{
    display:none;
}

.showItem{
    display:block;
}

.row-show-hide{
    position:relative;
    top:5px;
    width:120px;
    height:102px;
    margin-right:16px;
    border:solid 1px #B59343;
    cursor:pointer;
}

.black{
    background-color:#000000;
}

.grey{
    background-color:#ffffff;
}

.row-show-hide-text{
    position:absolute;
    width:100%;
    height:14px;
    left:0px;
    top:50%;
    transform:translateY(-50%);

    font-family: "Lato";
    font-size: 10px;
    color: #B59343;
    letter-spacing: 0.7px;
    text-align: center;
}


.video-player{
    position:fixed;
    top:0px;
    left:-100vw;
    width:100vw;
    height:100vh;
    background:#000000;
    overflow-x:auto;
    overflow-y:scroll;
    -webkit-transition: opacity 1s; -moz-transition: opacity 1s; -o-transition: opacity 1s; transition: opacity 1s;
    opacity:0;
}

.portrait .videoplayer-header{
    width:100%;
    background:#000000;
    position:absolute;
    top:0px;
    left:0px;
    height:48px;
}

.landscape .videoplayer-header{
    width:20px;
    height:12px;
    position:absolute;
    top:15px;
    left:31px;
}

.portrait .videoplayer-headerInner{
    position:relative;
    height:48px;
    width:95%;
    margin: 0px auto;
}

.landscape .videoplayer-headerInner{
    position:relative;
    width:20px;
    height:12px;
}

.videoplayer-headerBar{
    display:none;
}

.portrait #videoplayerBack{
    display:none;
}

.landscape #videoplayerBack{
    display:none;
}

.portrait #videoplayerBack2{
    position:absolute;
    left:17px;
    top:20px;
    width:20px;
    height:12px;
    background:url('../img/backArrow.png') no-repeat center center;
    cursor:pointer;
}

.landscape #videoplayerBack2{
    position:absolute;
    left:0px;
    top:0px;
    width:20px;
    height:12px;
    background:url('../img/backArrow.png') no-repeat center center;
    cursor:pointer;
}

.portrait .embed-container {
    overflow: hidden;
    width:100vw;
    height:50%;
    pointer-events:none;
}

.landscape .embed-container {
    position: relative;
    overflow: hidden;
    top:0px;
    height:100vh;
    pointer-events:none;
}
    
.portrait .embed-container iframe, .portrait .embed-container object, .portrait .embed-container embed {
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    pointer-events:auto;
    }

.landscape .embed-container iframe, .landscape .embed-container object, .landscape .embed-container embed {
    position: absolute;
    top: 0px;
    left: 50%;
    transform:translateX(-50%);
    height: 100vh;
    pointer-events:auto;
    }

.portrait #videoEmbedWrapper{
    width: 100%;
}

.landscape #videoEmbedWrapper{
    height: 100vh;
}

.portrait #videoPlayerText{
    position:relative;
    max-width:100vw;
    width:100vw;
    padding:0px 20px;
}

.landscape #videoPlayerText{
    display:none;
}

#videoPlayerBrand{
    width:75%;
    margin-top:0px;
    font-family: Lato;
    font-size: 14px;
    color: #9B9B9B;
    letter-spacing: -0.12px;
    text-align: left;
}

#videoPlayerTitle{
    width:75%;
    margin-bottom:5px;
    font-family: Lato;
    font-size: 21px;
    color: #F2F2F2;
    letter-spacing: -0.29px;
    line-height: 24px;
    text-align: left;
}

#videoPlayerDescription{
    width:75%;
    margin-bottom:5px;
    font-family: Lato;
    font-size: 15px;
    color: #9B9B9B;
    letter-spacing: 0.2px;
    line-height: 24px;
    text-align: left;   
    padding-bottom:60px;
}

#videoPlayerCategoryMobile{
    width:100%;
    font-family: Lato;
    font-size: 13px;
    color: #9B9B9B;
    letter-spacing: 0;
    text-align: left;
    margin-bottom:20px;
}

#videoPlayerCategoryDesktop{
    display:none;
}

#videoPlayerHit{
    position:absolute;
    left:0px;
    top:0px;
    width:100%;
    height:100%;
    background:url('../img/playbutton.png') no-repeat center center;
}

#videoplayerDownload{
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
    width:625px;
    height:324px;
    background:#ffffff;
    display:none;
}

#videoplayerDownloadTitle{
    font-family: Lato;
    font-size: 22px;
    color: #4A4A4A;
    letter-spacing: 0.27px;
}

#videoplayerDownloadItem1{
    position:absolute;
    left:35px;
    top:68px;
    width:578px;
    height:44px;
}

#videoplayerDownloadItem2{
    position:absolute;
    left:35px;
    top:124px;
    width:578px;
    height:44px;
}

#videoplayerDownloadItem3{
    position:absolute;
    left:35px;
    top:180px;
    width:578px;
    height:44px;
}

#videoplayerDownloadItem4{
    position:absolute;
    left:35px;
    top:226px;
    width:578px;
    height:44px;
}

#videoplayerDownloadItemTxt1{
    position:absolute;
    left:0px;
    top:0px;
    width:150px;
    height:40px;
    font-family: Lato;
    font-weight:bold;
    font-size: 18px;
    color: #4A4A4A;
    letter-spacing: 0.22px;
    line-height:40px;
}

#videoplayerDownloadItemTxt2{
    position:absolute;
    right:211px;
    top:0px;
    width:237px;
    height:40px;
    font-family: Lato;
    font-size: 18px;
    color: #4A4A4A;
    letter-spacing: 0.22px;
    text-align:right;
    line-height:40px;
}

#videoplayerDownloadItemBtn1{
    position:absolute;
    right:0px;
    top:0px;
    width:191px;
    height:40px;
    background:#000000;
    font-family: Lato;
    font-weight:bold;
    font-size: 14px;
    color: #B59343;
    letter-spacing: 0;
    text-align: center;
    line-height:40px;
}

.portrait .videoHeaderTitle{
    background: url('../img/m_courageous_logo.svg') no-repeat center center;
    position:absolute;
    width:189px;
    height:22px;
    top:15px;
    left:50%;
    transform:translateX(-50%);
}

.landscape .videoHeaderTitle{
    display:none;
}

#videoHeaderShare{
    background: url('../img/video_share.svg') no-repeat center center;
    position:absolute;
    width:16px;
    height:22px;
    right:50px;
    top:0px;
    cursor:pointer;
    pointer-events:auto;
}

#videoHeaderVim{
    background: url('../img/vimeo.png') no-repeat center center;
    position:absolute;
    width:17px;
    height:15px;
    right:80px;
    top:7px;
    cursor:pointer;
    pointer-events:auto;
}

#videoHeaderNotification{
    position:absolute;
    width:50px;
    height:40px;
    left:50%;
    transform:translateX(-50%);
    bottom:25px;

    font-family: "Conduit";
    font-size: 10px;
    color: #9B9B9B;
    letter-spacing: 1.4px;
    text-align: center;
    pointerEvents:none;
    opacity:0;
}

#videoHeaderPlus{
    position:absolute;
    width:25px;
    height:27px;
    right:20px;
    top:0px;
    cursor:pointer;
    pointer-events:auto;
    color:#ffffff;
}

#videoPlayerHide{
    position:relative;
    margin-left:auto;
    margin-right:auto;
    top:0px;
    height:100%;
    pointer-events:none;
}

.portrait #videoPlayerOverlay{
    position:absolute;
    left:0px;
    top:0px;
    width: 100vw;
    height:50%;
    background-color: rgba(0, 0, 0, 0.68); 
}

.landscape #videoPlayerOverlay{
    position:absolute;
    left:0px;
    top:0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.68); 
}

.portrait #videoPlayerPrevBtnHolder{
    position:absolute;
    width:25%;
    left:15px;
    top:54px;
    cursor:pointer;
    pointer-events:auto;
}

.landscape #videoPlayerPrevBtnHolder{
    position:absolute;
    width:25%;
    left:31px;
    top:43px;
    cursor:pointer;
    pointer-events:auto;
}

.portrait #videoPlayerPrevBtn{
    width:100%;
    font-family: Lato;
    font-weight:bold;
    font-size: 12px;
    color: #B59343;
    letter-spacing: -0.14px;
    text-align: left;
}

.landscape #videoPlayerPrevBtn{
    width:100%;
    font-family: Lato;
    font-weight:bold;
    font-size: 14px;
    color: #B59343;
    letter-spacing: -0.16px;
    text-align: left;
    margin-bottom:15px;
}

.portrait #videoPlayerPrevBrand{
    display:none;
}

.landscape #videoPlayerPrevBrand{
    width:100%;
    text-align: left;
    font-family: Seravek;
    font-size: 14px;
    color: #9B9B9B;
    letter-spacing: -0.12px;
    margin-bottom:3px;
}

.portrait #videoPlayerPrevTitle{
    display:none;
}

.landscape #videoPlayerPrevTitle{
    width:100%;
    text-align: left;
    font-family: Lato;
    font-size: 18px;
    color: #FFFFFF;
    letter-spacing: -0.2px;
    line-height: 20px;
    margin-bottom:5px;
}

.portrait #videoPlayerPrevCategory{
    display:none;
}

.landscape #videoPlayerPrevCategory{
    width:100%;
    text-align: left;
    font-family: Seravek;
    font-size: 12px;
    color: #9B9B9B;
    letter-spacing: -0.1px;
}

.portrait #videoPlayerNextBtnHolder{
    position:absolute;
    width:25%;
    right:15px;
    top:54px;
    cursor:pointer;
    pointer-events:auto;
}

.landscape #videoPlayerNextBtnHolder{
    position:absolute;
    width:25%;
    right:31px;
    top:43px;
    cursor:pointer;
    pointer-events:auto;
}

.portrait #videoPlayerNextBtn{
    margin-left:auto; 
    margin-right:0;
    width:100%;
    font-family: Lato;
    font-weight:bold;
    font-size: 12px;
    color: #B59343;
    letter-spacing: -0.14px;
    text-align: right;
}

.landscape #videoPlayerNextBtn{
    margin-left:auto; 
    margin-right:0;
    width:100%;
    font-family: Lato;
    font-weight:bold;
    font-size: 14px;
    color: #B59343;
    letter-spacing: -0.16px;
    text-align: right;
    margin-bottom:15px;
}

.portrait #videoPlayerNextBrand{
    display:none;
}

.landscape #videoPlayerNextBrand{
    margin-left:auto; 
    margin-right:0;
    width:100%;
    text-align: right;
    font-family: Seravek;
    font-size: 14px;
    color: #9B9B9B;
    letter-spacing: -0.12px;
    margin-bottom:3px;
}

.portrait #videoPlayerNextTitle{
    display:none;
}

.landscape #videoPlayerNextTitle{
    margin-left:auto; 
    margin-right:0;
    width:100%;
    text-align: right;
    font-family: Lato;
    font-size: 18px;
    color: #FFFFFF;
    letter-spacing: -0.2px;
    line-height: 20px;
    margin-bottom:5px;
}

.portrait #videoPlayerNextCategory{
    display:none;
}

.landscape #videoPlayerNextCategory{
    margin-left:auto; 
    margin-right:0;
    width:100%;
    text-align: right;
    font-family: Seravek;
    font-size: 12px;
    color: #9B9B9B;
    letter-spacing: -0.1px;
}

.prompt-wrapper{
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    display:none;
    opacity:0;
    -webkit-transition: opacity 1s;
    -moz-transition: opacity 1s;
    -o-transition: opacity 1s;
    transition: opacity 1s;
}

.prompt-bg{
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    background:#ffffff;
    opacity:0.6;
    z-index:100;
}

.prompt{
    position:relative;
    width:400px;
    height:160px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    border:solid 1px #000000;
    background:#ffffff;
    z-index:101;
    
}

.pdf-text{
    position:absolute;
    top:50px;
    left:50%;
    transform: translateX(-50%);
    line-height:14px;
    font-size:14px;
    font-family: "Conduit";
    color:#000000;
}

.download-pdf-btn{
    position:absolute;
    top:99px;
    left:91px;
    width:122px;
    height:20px;
    border:solid 1px #000000;
    /*cursor:no-drop;
    opacity:0.3;*/
    cursor:pointer;
}
.download-pdf-btn-txt{
    position:absolute;
    top:5px;
    left:10px;
    line-height:10px;
    font-size:10px;
    font-family: "Conduit";
    color:#000000;
    width:100%;
    height:12px;
    pointer-events:none;
}

.download-pdf-cancel{
    position:absolute;
    top:99px;
    left:233px;
    width:77px;
    height:20px;
    border:solid 1px #000000;
    cursor:pointer;
}

.download-pdf-cancel-txt{
    position:absolute;
    top:5px;
    left:10px;
    line-height:10px;
    font-size:10px;
    font-family: "Conduit";
    color:#000000;
    width:100%;
    height:12px;
    pointer-events:none;
}

#cartField{
    position:absolute;
    border-left:solid 1px #eeeeee;
    -webkit-transition: right 1s; -moz-transition: right 1s; -o-transition: right 1s; transition: right 1s;
    background:#ffffff;
    overflow:hidden;
    top: calc(100vh + 130px);
    height:calc(100vh - 130px);
    width:100vw;
}

#cartContent{
    width:100%;
    height:100%;
    margin-top:20px;
    overflow-y:scroll;
    overflow-x:hidden;
}

.cartFieldClose{

}

.cartFieldOpen{

}

.cartHidden{
    right:100vw;
    
}

.cartVisible{
    right:0px;
    
}

#cartInner{
    width:100%;
    padding:20px;
}

.cartRow{
    position:relative;
    width:100%;
    height:102px;
    padding:5px;
    margin:5px 0px;
    border-bottom:solid 1px #E9E9E9;

    font-family: "Seravek";
    font-size: 15px;
    color: #4A4A4A;
    letter-spacing: -0.9px;
    pointer-events:none;
}

.cartImg{
    position:absolute;
    height:75px;
    width:100px;
    top:10px;
    left:0px;
    background-repeat:no-repeat;
    background-position: center center;
    background-size: auto 100%;
}

.cartItemText{
    position:absolute;
    left:110px;
    top:16px;
    width:100%;
    
    font-family: "Seravek";
    font-size: 12px;
    color: #4A4A4A;
    letter-spacing: -0.1px;
}

.cartItemTitle{
    position:absolute;
    left:110px;
    top:33px;
    width:300px;
    
    font-family: "Conduit";
    font-size: 16px;
    color: #4A4A4A;
    letter-spacing: -0.17px;
}

.cartItemCategory{
    position:absolute;
    left:110px;
    top:48px;
    width:100px;
    font-family: "Seravek";
    font-size: 12px;
    color: #4A4A4A;
    letter-spacing: -0.1px;
}

.cartDelete{
    position:absolute;
    height:20px;
    width:20px;
    top:-10px;
    right:0px;
    
    font-family: "Seravek";
    font-size: 30px;
    color: #4A4A4A;
    letter-spacing: 0;
    cursor:pointer;
    pointer-events:all;
}

#cartTrash{
    background:url('../img/trash.png') no-repeat left;
    width:11px;
    height:14px;
    position:absolute;
    right:36px;
    top:2px;
    cursor:pointer;
}

#cartHamburger{
    background:url('../img/hamburger.png') no-repeat left;
    width:14px;
    height:14px;
    position:absolute;
    right:61px;
    top:2px;
    cursor:pointer;
}

#cartHamburgerMenu{
    position:absolute;
    padding:15px;
    /*height:118px;*/
    height:158px;
    width:201px;
    top:20px;
    right:20px;
    border:solid 1px #979797;
    background-color:#ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    display:none;
}

#cartHamburgerMenu div{
    padding:9px 0px;
    pointer-events:all;
    font-family: "Conduit";
    font-size: 14px;
    color: #4A4A4A;
    letter-spacing: 1.4px;
    text-align: center;
    cursor:pointer;
}

#cartHamburgerMenu div:hover{
    color:#B59343;
}

#cartPdf div{
    background:url('../img/pdf.png') no-repeat left;
    width:9px;
    height:10px;
    overflow:hidden;
    display:inline-block;
}

#cartCopy div{
    background:url('../img/copy.png') no-repeat left;
    width:14px;
    height:14px;
    overflow:hidden;
    display:inline-block;
}

#cartCopyNotification{
    position:absolute;
    top:87px;
    left:50%;
    transform:translateX(-50%);
    width:100%;

    font-family: "Conduit";
    font-size: 10px;
    color: #4A4A4A;
    letter-spacing: 1.4px;
    text-align: center;
    pointerEvents:none;
    opacity:0;
}

#cartShare div{
    background:url('../img/copy.png') no-repeat left;
    width:14px;
    height:14px;
    overflow:hidden;
    display:inline-block;
}

#cartReplace div{
    background:url('../img/replace.png') no-repeat left;
    width:14px;
    height:14px;
    overflow:hidden;
    display:inline-block;
}

#cartHamburgerMenu div:hover div{
    background-position:-20px 3px;
}

