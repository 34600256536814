@font-face {
    font-family: 'CNNSansDisplay';
    src: url('../fonts/CNNSans-Regular.woff2') format('woff2'),
        url('../fonts/CNNSans-Regular.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Conduit';
    src: url('../fonts/ConduitITCStd-Light.woff2') format('woff2'),
        url('../fonts/ConduitITCStd-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Conduit';
    src: url('../fonts/ConduitITCStd-Regular.woff2') format('woff2'),
        url('../fonts/ConduitITCStd-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Conduit';
    src: url('../fonts/ConduitITCStd-ExtraBold.woff2') format('woff2'),
        url('../fonts/ConduitITCStd-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Conduit';
    src: url('../fonts/ConduitITCStd-Bold.woff2') format('woff2'),
        url('../fonts/ConduitITCStd-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Conduit';
    src: url('../fonts/ConduitITCStd-Medium.woff2') format('woff2'),
        url('../fonts/ConduitITCStd-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url("../fonts/Lato-Light.woff") format("woff"),
    url("../fonts/Lato-Light.woff2") format("woff2");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url("../fonts/Lato-Regular.woff") format("woff"),
    url("../fonts/Lato-Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
}
 
 @font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Bold.woff2') format('woff2'),
        url('../fonts/Lato-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Seravek';
    src: url("../fonts/Seravek-Medium.woff") format("woff"),
        url("../fonts/Seravek-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
}
